@font-face {
  font-family: 'Denk One';
  src: url('./fonts/DenkOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'Denk One', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

